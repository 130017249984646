import React, { FC, memo, useMemo } from 'react';
import { List, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { ListItemTrialLink } from '../..';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useTrialUser } from '../../../../hooks';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { Item, roleMenuItems } from '../../../../routes/sidebar-items';
import some from 'lodash/some';
import {
  ConfigurationStatusDocument,
  ConfigurationStatusQuery,
  ConfigurationStatusQueryVariables,
  RoleType,
} from '../../../../generated/graphql';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  drawer: ({ drawerWidth = 240 }: any) => ({
    width: drawerWidth,
    flexShrink: 0,
  }),
  drawerPaper: ({ drawerWidth = 240 }: any) => ({
    width: drawerWidth,
  }),
  capitalize: {
    textTransform: 'capitalize',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  drawerIcon: {
    color: theme.palette.common.white,
  },
  completeIcon: {
    position: 'absolute',
    right: -30,
  },
  drawerLi: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1) / 2,
    paddingBottom: theme.spacing(1) / 2,
  },
  drawerLink: {
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.grey['600'],
    },
  },
  drawerLinkSelected: {
    backgroundColor: theme.palette.grey['600'],
  },
}));

const Wrapper: React.FC<{ title: string; open: boolean; children: React.ReactElement }> = ({ title, open, children }) => {
  const classes = useStyles();
  return open ? children :
    <Tooltip title={title}
             classes={{
               tooltip: classes.capitalize,
             }}
             placement="right">{children}</Tooltip>;
};

const matchRegexPrefix = '/trials/trial-.{10}';

type Props = {
  locked?: boolean;
  open: boolean;
}
export const ItemList: FC<Props> = memo(({ open }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { userCan, user } = useTrialUser();
    const { data } = useQuery<ConfigurationStatusQuery, ConfigurationStatusQueryVariables>(ConfigurationStatusDocument, {
      skip: user?.role !== RoleType.Pd,
    });
    const { pathname } = useLocation();
    const items: Item[] = useMemo(
      () => {
        if (user?.role && roleMenuItems[user.role]) {
          return roleMenuItems[user.role].map(
            (i) => {
              i.complete = !some(data?.configurationStatus.hint, (v) => v === i.hint);
              return i;
            },
          );
        }
        return [];
      },
      [data, user],
    );

    return (
      <List>
        {items.map(
          (item) => {
            if (item.perm && !userCan(item.perm)) {
              return null;
            }
            const title = t(item.label, item.labelProp);
            const pathToMatch = item.to.replace(/\/$/, '');
            const re = new RegExp(matchRegexPrefix + pathToMatch + (item.to === '/' ? '$' : ''));
            return (
              <Wrapper open={open} title={title} key={item.to}>
                <li className={classes.drawerLi}>
                  <ListItemTrialLink
                    to={item.to}
                    className={clsx(classes.drawerLink, {
                      [classes.drawerLinkSelected]: re.test(pathname),
                    })}
                    dense
                    button
                    disabled={item.disabled || (!!pathToMatch && pathname.search(pathToMatch) !== -1)}
                  >
                    <ListItemIcon className={classes.drawerIcon}>{item.icon}</ListItemIcon>
                    <ListItemText className={classes.capitalize} primary={`${title}`}/>
                    {item.completeIcon && open &&
                    <ListItemIcon className={classes.completeIcon}>
                      {item.complete ?
                        <Check color={'primary'} fontSize={'small'}/>
                        :
                        <Close color={'error'} fontSize={'small'}/>
                      }
                    </ListItemIcon>}
                  </ListItemTrialLink>
                </li>
              </Wrapper>
            );
          })}
      </List>
    );
  },
);
