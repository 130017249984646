import { Cb } from '../interfaces';
import {
  CreateFormDocument,
  CreateFormMutation,
  CreateFormMutationVariables,
} from '../generated/graphql';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { ErrorHandler } from '@hu-care/react-utils';
import { useStore } from '@hu-care/react-ui-store';
import { errorToast } from '../utils/error-toast';

export interface CreateFormForm {
  title: string;
}

export const useCreateForm = (cb?: Cb<CreateFormMutation['createForm']>) => {
  const [mutate, rest] = useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, {
    refetchQueries: ['GetFormsList', 'ConfigurationStatus'],
    awaitRefetchQueries: true,
  });
  const { dispatch } = useStore();

  const createForm = useCallback(async (variables: CreateFormForm) => {
    try {
      const result = await mutate({
        variables,
      });
      if (typeof cb === 'function') {
        cb(result.data!.createForm);
      }
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
    }
  }, [mutate, dispatch, cb]);

  return {
    createForm,
    ...rest,
  };
};
