import { scopeCan } from '@hu-care/react-utils';
import { useTrialCred } from './useTrialCred';

export const useTrialUser = () => {
  const { user } = useTrialCred();
  return {
    user: user,
    userCan: (scope: string) => scopeCan(user?.scopes || [], scope),
  };
};
