import React from 'react';
import { useForm } from 'react-hook-form';
import { CircularProgress, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Actions, useStore } from '@hu-care/react-ui-store';
import { useHistory } from 'react-router';
import { useAcceptInvite } from '../../../hooks';

export const AcceptInvite: React.FC<{ code?: string }> = ({ code }) => {
  const { push } = useHistory();
  const { dispatch } = useStore();
  const { t } = useTranslation('invite');
  const { register, errors, handleSubmit } = useForm<{ code: string }>({
    defaultValues: {
      code,
    },
  });
  const { acceptInvite, loading } = useAcceptInvite((trial) => {
    dispatch({
      type: Actions.DIALOG_CLOSE,
    });
    push(`/trials/trial-${trial.id}`);
  });

  return (
    <form onSubmit={handleSubmit(acceptInvite)}>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'}>
              {t(code ? 'confirm_detail' : 'detail')}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              variant={'outlined'}
              label={t('code')}
              fullWidth
              id={'invite-code-input'}
              inputRef={register({ required: t<string>('common:required_field') })}
              name="code"
              error={!!errors.code}
              helperText={errors.code?.message}
            />
          </Grid>
          <Grid component={Box} item xs={12} justifyContent={'flex-end'} display={'flex'}>
            <Button
              id={'accept-invite-btn'}
              disabled={loading}
              variant={'outlined'}
              color={'primary'}
              type={'submit'}>
              {!loading ? t('accept_invite') : <CircularProgress size={24}/>}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};
