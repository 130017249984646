import React, { forwardRef, useMemo } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_TRIAL_CRED } from '../../../apollo/localSchema';
import { GetTrialCredQuery } from '../../../generated/graphql';

export const TrialLink = forwardRef<any, LinkProps>((props, ref) => {
  const { data } = useQuery<GetTrialCredQuery>(GET_TRIAL_CRED);
  const { state } = useLocation();
  const id = data?.trialCred?.id;
  const to: any = useMemo(() => {
    switch (typeof props.to) {
      case 'string':
        if (!props.to.startsWith('/')) {
          // relative path, leave as is
          return props.to;
        }
        return {
          pathname: `/trials/trial-${id}${props.to}`,
          state,
        };
      case 'object':
        return {
          ...props.to,
          pathname: `/trials/trial-${id}${props.to.pathname}`,
          state: {
            ...state,
            ...props.to.state,
          },
        };
      case 'function':
        console.warn('Unsupported to as a function');
        return props.to; // unsupported
    }
  }, [props.to, id, state]);
  if (!id) {
    return null;
    // throw new Error('Trial not found, you cannot use <TrialLink/> outside a Trial');
  }
  return (
    <Link {...props} to={to} ref={ref as any}/>
  );
});
