import React, { FC, ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

type Props = {
  message: string;
  icon?: ReactNode;
}
const ErrorMessageComponent: FC<Props> = ({ message = 'Broken URL!', icon }: Props) => (
  <Box my={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
    {icon &&
    <Box my={2} display={'flex'} justifyContent={'center'}>
      {icon}
    </Box>}
    <Typography color={'error'} align={'center'} variant={'caption'} component={'p'}>
      {message}
    </Typography>
  </Box>
);

export default ErrorMessageComponent;
