import { Actions } from '@hu-care/react-ui-store';
import { NotificationAction } from '@hu-care/react-ui-store/dist/actions';

export const errorToast = (message: string): NotificationAction => ({
  type: Actions.TOAST_OPEN,
  message,
  severity: 'error',
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  autoHideDuration: 5000,
});
