import { RoleType } from '../generated/graphql';

export const SPONSOR_ROLES = [RoleType.Pd, RoleType.Cr, RoleType.St, RoleType.Dm];

export const CRO_ROLES = [RoleType.Cd, RoleType.Cr];

export const SITE_ROLES = [RoleType.Pi, RoleType.Si, RoleType.Sc, RoleType.Cr];

export const getRoleName = (role?: RoleType) => {
  switch (role) {
    case RoleType.Cd:
      return 'Clinical Director';
    case RoleType.Cr:
      return 'Cra Monitor';
    case RoleType.De:
      return 'Data entry';
    case RoleType.Dm:
      return 'Data Manager';
    case RoleType.Pd:
      return 'Project Director';
    case RoleType.Pi:
      return 'Principal Investigator';
    case RoleType.Sc:
      return 'Study Coordinator';
    case RoleType.Si:
      return 'Sub Investigator';
    case RoleType.St:
      return 'Statistician';
    default:
      return 'N/A';
  }
};
