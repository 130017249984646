import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export enum RoutesKeys {
  dashboard = 'dashboard',
  acceptInvite = 'accept-invite',
  trials = 'trials',
  trial = 'trial',
  profile = 'profile',
  croList = 'cro-list',
  cro = 'cro',
  scheduling = 'scheduling',
  controlPlan = 'control-plan',
  patientDiaries = 'patient-diaries',
  site = 'site',
  patientSchedules = 'patient-schedules',
  settings = 'settings',
  protocol = 'protocol',
  formList = 'form-list',
  compileForm = 'compile-form',
  dropOutPatient = 'drop-out-patient',
  readForm = 'read-form',
  editForm = 'edit-form',
  summary = 'summary',
  patients = 'patients',
  patientDetails = 'patient-details',
  sitePatients = 'site-patients',
  croPatients = 'cro-patients',
  enrollPatient = 'enroll-patient',
  notFound = 'not-found',
  siteDashboard = 'site-dashboard',
  siteHome = 'site-home',
  croDashboard = 'cro-dashboard',
  croSite = 'cro-site',
  monitorQueries = 'monitor-queries',
  monitorForms = 'monitor-form',
  eventForm = 'event-form',
  monitorDashboard = 'monitor-dashboard',
  monitorSite = 'monitor-site',
}

export interface RouteType {
  key: RoutesKeys;
  path: string;
  component: any;
  exact?: boolean;
  children?: any[];
  config: {
    title?: string;
    titleArgs?: Record<string, any>;
    breadcrumbs?: boolean;
    back?: boolean;
    scopes?: string | string[];
    auth0Scopes?: string | string[];
    hideSidebar?: boolean;
    maxWidth?: Breakpoint;
  };
}
