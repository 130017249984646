import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const Page403Container = () => {
  return (
    <Box my={4}>
      <Typography variant={'h2'} color={'textSecondary'} align={'center'}>403</Typography>
      <Typography variant={'subtitle2'} color={'secondary'} align={'center'}>You don't have permission to visit this page</Typography>
    </Box>
);
};

export default Page403Container;
