import React, { memo, Suspense, useEffect, useState } from 'react';
import { Router, Switch } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { LayoutProvider, Loading } from '@hu-care/react-layout';
import { useAuth0 } from '@hu-care/react-auth0';
import history from './routes/history';
import { renderRoutes } from './routes';
import { makeClient } from './apollo/client';
import { Layout } from './components/layout/layout';
import { commonRoutes } from './routes/common.routes';
import './i18n';
import { errorRoutes } from './routes/error.routes';
import ErrorMessageComponent from './components/shared-ui/error-message/error-message';
import { useTranslation } from 'react-i18next';
import { WifiOff } from '@material-ui/icons';

export const App = memo(() => {
  const { loading, loginWithRedirect, isAuthenticated, getTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const [apollo, setApollo] = useState<ApolloClient<any> | null>(null);
  const [isOnline, setOnline] = useState<boolean>(true);
  useEffect(
    () => {
      window.addEventListener('load', () => {
        const updateOnlineStatus = () => {
          setOnline(navigator.onLine);
        };
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
      });
    },
    [],
  );
  useEffect(() => {
    if (!loading) {
      console.log('EndPoint:', process.env.REACT_APP_API_ENDPOINT);
      setApollo(makeClient(getTokenSilently));
    }
  }, [loading, getTokenSilently]);

  if (loading || !apollo) {
    return (
      <Loading/>
    );
  }
  if (!isAuthenticated) {
    loginWithRedirect().then(() => console.log('Redirect user to login page'));
  }
  if (!isOnline) {
    return (
      <ErrorMessageComponent
        icon={<WifiOff color={'error'} fontSize={'large'}/>}
        message={t('offline_error', 'Unable to connect to the Internet')}/>
    );
  }
  return (
    <ApolloProvider client={apollo}>
      <Router history={history}>
        <LayoutProvider routes={commonRoutes}>
          <Layout>
            <Suspense fallback={<Loading/>}>
              <Switch>
                {isAuthenticated && renderRoutes([...commonRoutes, ...errorRoutes])}
              </Switch>
            </Suspense>
          </Layout>
        </LayoutProvider>
      </Router>
    </ApolloProvider>
  );
});
