import { useQuery } from '@apollo/client';
import {
  ConfigurationStatusDocument,
  ConfigurationStatusQuery,
  ConfigurationStatusQueryVariables,
} from '../generated/graphql';

export const useTrialStatus = () => {
  const { data } = useQuery<ConfigurationStatusQuery, ConfigurationStatusQueryVariables>(ConfigurationStatusDocument);

  return data?.configurationStatus || {
    completed: null,
    total: null,
    current: null,
    hint: null,
    status: null,
  };
};
