import { useQuery } from '@apollo/client';
import { CurrentOperatorDocument, CurrentOperatorQuery } from '../generated/graphql';
import { useTrialUser } from './useTrialUser';

export const useCurrentOperator = () => {
  const { user } = useTrialUser();
  const { data, loading, error } = useQuery<CurrentOperatorQuery>(CurrentOperatorDocument, {
    fetchPolicy: 'cache-first',
    skip: !user,
  });

  return {
    loading,
    error,
    operator: data?.currentOperator,
  }
}
