import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

export const errorRoutes: RouteType[] = [
  {
    key: RoutesKeys.notFound,
    path: '/*',
    exact: false,
    component: asyncLoad(() => import('../containers/page-404.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'page-not-found',
    },
  },
];
