import { useQuery } from '@apollo/client';
import { GetAvailableTrialsDocument, GetAvailableTrialsQuery } from '../generated/graphql';

export const useGetAvailableTrials = () => {
  const { data, ...rest } = useQuery<GetAvailableTrialsQuery>(GetAvailableTrialsDocument, {
    context: {
      headers: {
        'X-Hucare-Hash': '',
      },
    },
    fetchPolicy: 'network-only',
    pollInterval: 36000,
  });
  return {
    data,
    ...rest,
  };
};
