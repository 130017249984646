import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  CreateSiteDocument,
  CreateSiteMutation,
  CreateSiteMutationVariables,
  GetCroDocument,
  GetCroQuery,
  GetCroQueryVariables,
} from '../generated/graphql';
import { useStore } from '@hu-care/react-ui-store';
import { useAuth0 } from '@hu-care/react-auth0';
import { ErrorHandler } from '@hu-care/react-utils';
import { Cb } from '../interfaces';
import { errorToast } from '../utils/error-toast';

export interface CreateSiteForm {
  code: string;
  description: string;
  patient_target: number;
  operator: {
    email: string;
    description: string;
  };
}

export const useCreateSite = (croUid: string, cb?: Cb<CreateSiteMutation['createSite']>) => {
  const { dispatch } = useStore();
  const { getTokenSilently } = useAuth0();
  const [mutate, rest] = useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, {
    update: (cache, mutationResult) => {
      try {
        const oldCro = cache.readQuery<GetCroQuery, GetCroQueryVariables>({
          query: GetCroDocument,
          variables: { id: croUid },
        });
        if (oldCro?.getCro && mutationResult.data?.createSite) {
          cache.writeQuery<GetCroQuery, GetCroQueryVariables>({
            query: GetCroDocument,
            variables: { id: croUid },
            data: {
              getCro: {
                ...oldCro.getCro,
                sites: [...oldCro.getCro.sites, mutationResult.data.createSite],
              },
            },
          });
        }
      } catch (e) {
        // Nothing to update
      }
    },
  });

  const createSite = useCallback(async (values: CreateSiteForm) => {
    try {
      const result = await mutate({
        variables: {
          ...values,
          patient_target: +values.patient_target,
          cro_id: croUid,
          authToken: `${await getTokenSilently()}`,
        },
        refetchQueries: ['ConfigurationStatus'],
      });
      if (typeof cb === 'function') {
        cb(result.data!.createSite);
      }
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
    }
  }, [mutate, croUid, getTokenSilently, cb, dispatch]);

  return {
    createSite,
    ...rest,
  };
};
