import { useQuery } from '@apollo/client';
import {
  ExchangeTokenDocument,
  ExchangeTokenQuery,
  ExchangeTokenQueryVariables,
  GetTrialCredQuery,
  TrialUser,
} from '../generated/graphql';
import { useEffect } from 'react';
import { decodeJwt } from '@hu-care/react-utils';
import { GET_TRIAL_CRED } from '../apollo/localSchema';

export const useTrialToken = (uuid: string) => {
  const { data, client, ...rest } = useQuery<ExchangeTokenQuery, ExchangeTokenQueryVariables>(ExchangeTokenDocument, {
    variables: {
      uuid,
    },
    context: {
      headers: {
        'X-Hucare-Hash': '',
      },
    },
    fetchPolicy: 'cache-first',
  });

  useEffect(() => {
    if (data?.exchangeToken) {
      const trialUser: TrialUser = decodeJwt(data.exchangeToken) as TrialUser;
      trialUser.scopes = (trialUser.scopes as unknown as string).split(',');
      client.writeQuery<GetTrialCredQuery>({
        query: GET_TRIAL_CRED,
        data: {
          trialCred: {
            __typename: 'TrialCred',
            hash: trialUser.hash,
            token: data.exchangeToken,
            id: uuid,
            user: {
              __typename: 'TrialUser',
              ...trialUser,
            },
          },
        },
      });
    }
  }, [data, client, uuid]);

  return {
    data,
    client,
    ...rest,
  };
};
