import { useQuery } from '@apollo/client';
import { GetTrialCredDocument, GetTrialCredQuery } from '../generated/graphql';

export const useTrialCred = () => {
  const { data } = useQuery<GetTrialCredQuery>(GetTrialCredDocument);

  return data?.trialCred || {
    user: null,
    hash: null,
    token: null,
    id: null,
  };
};
