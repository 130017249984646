import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { GET_TRIAL_CRED, initialData, typeDefs } from './localSchema';
import { GetTrialCredQuery } from '../generated/graphql';

export function makeClient(tokenGetter: () => Promise<string>) {
  const authLink = setContext(
    async (data, { headers, cache }) => {
      const trialCredentials = (cache as InMemoryCache).readQuery<GetTrialCredQuery>({
        query: GET_TRIAL_CRED,
      })?.trialCred;

      const newHeaders = {
        'X-Hucare-Hash': trialCredentials?.hash || '',
        ...headers,
      };

      newHeaders.Authorization = `Bearer ${newHeaders['X-Hucare-Hash'] === ''
        ? await tokenGetter()
        : trialCredentials?.token
      }`;

      return {
        headers: newHeaders,
      };
    },
  );

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
    credentials: 'same-origin',
    includeExtensions: true,
  });

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: cache,
    typeDefs,
    resolvers: {},
  });

  client.writeQuery({
    query: GET_TRIAL_CRED,
    data: initialData,
  });

  client.onResetStore(async () => client.writeQuery({
    query: GET_TRIAL_CRED,
    data: initialData,
  }));

  return client;
}
