import { gql } from '@apollo/client/core';

export const initialData = {
  trialCred: null,
};

export const typeDefs = gql`
  directive @client on FIELD
  type TrialUser {
    iss: String!
    iat: Int!
    exp: Int!
    sub: String!
    role: RoleType!
    scopes: [String!]!
    status: String!
    hash: String!
  }
  type TrialCred {
    hash: String
    token: String
    id: String
    user: TrialUser
  }
  extend type Query {
    trialCred: TrialCred
  }
`;

const trialUserFragment = gql`
  fragment TrialUserData on TrialUser {
    iss iat exp sub role scopes status hash
  }
`;

export const GET_TRIAL_CRED = gql`
  query GetTrialCred {
    trialCred @client {
      hash
      token
      id
      user @client {
        ...TrialUserData
      }
    }
  }
  ${trialUserFragment}
`;
