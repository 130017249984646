import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { Cached, CheckCircle, ChevronLeft, FolderOpen, Lock, LockOpen } from '@material-ui/icons';
import clsx from 'clsx';
import { useStyles } from '../styles';
import { ItemList } from './items';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import TrialHistory from './trial-history';
import { SideBarProps } from './side-bar';
import { TrialLink } from '../..';
import { useTrialCred } from '../../../../hooks';
import { useTrialStatus } from '../../../../hooks/useTrialStatus';
import Tooltip from '@material-ui/core/Tooltip';
import { useMutation } from '@apollo/client';
import {
  FreezeTrialDocument,
  FreezeTrialMutation,
  FreezeTrialMutationVariables,
  TrialStatus,
} from '../../../../generated/graphql';
import { useStore } from '@hu-care/react-ui-store';
import { ErrorHandler } from '@hu-care/react-utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorToast } from '../../../../utils/error-toast';

export const ConfigurationSideBar = memo<SideBarProps>(({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useTrialCred();
  const { dispatch } = useStore();
  const { completed, status } = useTrialStatus();
  const [freezeTrial, { loading: saving }] = useMutation<FreezeTrialMutation, FreezeTrialMutationVariables>(FreezeTrialDocument);
  const [locked, setLocked] = useState(false);
  useEffect(
    () => setLocked(status === TrialStatus.Freeze),
    [status],
  );
  const freeze = useCallback(
    async (freeze: boolean) => {
      try {
        await freezeTrial({
          variables: {
            freeze,
          },
          refetchQueries: ['ConfigurationStatus'],
          awaitRefetchQueries: true,
        });
        setLocked(freeze);
      } catch (e) {
        const { message } = new ErrorHandler(e);
        dispatch(errorToast(message));
      }
    },
    [dispatch, freezeTrial],
  );
  const trialStatus = useMemo(
    () => {
      if (status === TrialStatus.Freeze) {
        return 'FREEZED';
      } else if (status === TrialStatus.Start) {
        return 'STARTED';
      } else {
        return (completed ? 'READY' : 'IN PROGRESS');
      }
    },
    [completed, status],
  );
  const chipLabel = useMemo(
    () => {
      return open ? (locked ? t('open', 'Open') : t('freeze', 'Freeze')) : null;
    },
    [locked, open, t],
  );
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Grid container direction={'column'} spacing={3} style={{ height: '100%' }} wrap={'nowrap'}>
        <Grid item>
          <Grid container justify={'space-between'} alignItems={'center'} className={classes.toolbar}>
            <Grid item xs>
              <Box mx={1}>
                <Typography color={'inherit'} variant={'body2'}>
                  {t('configuration', { name: 'trial' })}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <IconButton
                id={'close-configuration-nav-bar-btn'}
                onClick={onClose} color={'inherit'}>
                <ChevronLeft/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider/>
        </Grid>
        <Grid item>
          <Typography
            align={'center'}
            component={'p'}
            variant={'caption'}>
            <small>STATUS</small>
          </Typography>
          {saving ?
            <Box display={'flex'} justifyContent={'center'} m={1}>
              <CircularProgress size={18}/>
            </Box> :
            (open ?
              <Typography
                gutterBottom
                color={'primary'}
                align={'center'}
                component={'p'}
                variant={'overline'}>
                {trialStatus}
              </Typography>
              :
              <Box display={'flex'} my={1} justifyContent={'center'}>
                <Tooltip title={trialStatus}>
                  {locked ?
                    <Lock color={'primary'}/>
                    : completed ? <CheckCircle color={'primary'}/> : <Cached color={'primary'}/>}
                </Tooltip>
              </Box>)}
          {completed && status !== TrialStatus.Start &&
          <Box mx={open ? 2 : 1.5} mt={2}>
            <Tooltip title={locked ? t('unfreeze_trial', 'Open the trial')! : t('freeze_trial', 'Freeze the trial')!}>
              <Chip color={completed ? 'primary' : 'default'}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    disabled={!completed || saving}
                    onClick={() => freeze(!locked)}
                    avatar={
                      <Avatar alt="lock">
                        {locked ? <LockOpen fontSize={'small'} color={'secondary'}/> :
                          <Lock fontSize={'small'} color={'secondary'}/>}
                      </Avatar>
                    }
                    label={chipLabel}/>
            </Tooltip>
          </Box>}
        </Grid>
        <Divider/>
        <Grid item>
          <ItemList open={open} locked={locked}/>
        </Grid>
        <Divider/>
        <Grid item>
          <Box
            mx={open ? 2 : 1.5}
            my={1}
            width={'auto'}>
            {open ?
              <Button
                id={'summary-btn'}
                startIcon={<FolderOpen color={locked ? 'primary' : 'inherit'}/>}
                variant={locked ? 'text' : 'contained'}
                color={locked ? 'primary' : 'secondary'}
                disabled={!locked}
                fullWidth
                component={TrialLink}
                to={'/summary'}
              >
                <Typography variant={'button'}
                            color={locked ? 'primary' : 'inherit'}>
                  {t('summary', 'Summary')}
                </Typography>
              </Button>
              :
              <IconButton
                id={'summary-btn'}
                component={TrialLink}
                to={'/summary'}
                color={locked ? 'primary' : 'inherit'}
                disabled={!locked}
              >
                <FolderOpen color={locked ? 'primary' : 'inherit'}/>
              </IconButton>
            }
          </Box>
        </Grid>
        <Divider/>
        <Grid item xs hidden={!open}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-end'}>
            {id && <TrialHistory id={id}/>}
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
});
