import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ExpandMore } from '@material-ui/icons';
import { IndexDb } from '../../../../utils';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';

const useStyles = makeStyles(
  (theme) => ({
      expansion: {
        backgroundColor: 'transparent',
        color: 'white',
      },
      details: {
        padding: '8px',
        backgroundColor: theme.palette.secondary.dark,
      },
      capitalize: {
        textTransform: 'capitalize',
      },
    }
  ),
);


const TrialHistory: FC<{ id: string }> = memo(({ id }) => {
  const classes = useStyles();
  const { pathname, state } = useLocation();
  const [routes, setRoutes] = useState<any[]>([]);
  const { t } = useTranslation();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  const getHistory = useCallback(
    async (id: string) => {
      try {
        const history = await IndexDb.getHistory(id, 8);
        if (mounted.current) {
          setRoutes(history);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [setRoutes],
  );

  useEffect(
    () => {
      getHistory(id);
    }, [id, getHistory, pathname],
  );

  return (
    <Accordion square defaultExpanded className={classes.expansion} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMore color={'inherit'}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant={'overline'} color={'inherit'}>
          {t('history', 'History')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <List style={{ width: '100%' }}>
          {!routes.length &&
          <Typography align={'center'} color={'inherit'} component={'p'}>
            --
          </Typography>}
          {routes.map(
            ({ pathname: to, label }, index) => (
              <ListItem key={`history-${index}`}>
                <Link
                  component={RouterLink}
                  color={pathname === to ? 'primary' : 'inherit'}
                  className={classes.capitalize}
                  to={{
                    pathname: to,
                    state,
                  }}>
                  • {label}
                </Link>
              </ListItem>
            ),
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
});

export default TrialHistory;
