import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

export const monitorRoutes: RouteType[] = [
  {
    key: RoutesKeys.monitorDashboard,
    path: '/trials/trial-:trial_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-dashboard.container')),
    config: {
      title: 'cro:cro_home',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.monitorForms,
    path: '/trials/trial-:trial_uuid/forms',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-forms.container')),
    config: {
      title: 'common:forms',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.eventForm,
    path: '/trials/trial-:trial_uuid/forms/form-:event_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-form.container')),
    config: {
      title: 'common:form',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.monitorQueries,
    path: '/trials/trial-:trial_uuid/queries',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-queries.container')),
    config: {
      title: 'common:queries',
      breadcrumbs: true,
      back: true,
    },
  },
];

