import React, { FC, memo, useCallback, useMemo } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Link as RouterLink, matchPath, useHistory, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBack } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import { useQuery } from '@apollo/client';
import { GetTrialCredQuery } from '../../../generated/graphql';
import { GET_TRIAL_CRED } from '../../../apollo/localSchema';
import { IndexDb } from '../../../utils';
import { routes, RoutesKeys } from '../../../routes';

const useStyles = makeStyles({
  capitalize: {
    textTransform: 'capitalize',
  },
});

type Props = {
  back?: boolean;
};

const generatePartialRoute = (elements: string[], index: number): string => {
  let route = '/';
  for (let i = 0; i <= index; i++) {
    route = route.concat(`${elements[i]}/`);
  }
  return route;
};


export const Breadcrumb: FC<Props> = memo(
  ({ back = false }) => {
    const { data } = useQuery<GetTrialCredQuery>(GET_TRIAL_CRED);
    const { t } = useTranslation();
    const { pathname, state } = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const id = data?.trialCred?.id;
    const moveBack = useCallback(
      () => {
        let partialRoute = pathname.split('/').filter((path: string) => !!path);
        partialRoute = partialRoute.splice(0, partialRoute.length - 1);
        if (partialRoute.length === 1) {
          return history.push('/');
        }
        partialRoute = `/${partialRoute.join('/')}`;
        const route = routes.some(({ path }) => matchPath(partialRoute, { exact: true, path }));
        if (route) {
          return history.push(partialRoute, state);
        }
        // TODO: replace goBack with recursive search of a valid previous route
        return history.goBack();
      },
      [history, pathname, state],
    );
    const items = useMemo(
      () => {
        const getRouteTitle = (element: string, partialRoute: string) => {
          const route = routes.find(({ path }) => matchPath(partialRoute, { exact: true, path }));
          // console.log(route?.config.title, partialRoute);
          if (route && route.key !== RoutesKeys.notFound && route.config.title) {
            if (state) {
              // console.log(route);
              try {
                const key = element.split('-')[0];
                // console.log(state, key);
                if (state[key]) {
                  element = element.replace(state[key]?.uuid, state[key]?.title);
                } else {
                  element = t(route.config.title, route.config.titleArgs);
                }
              } catch (e) {
                console.error(e);
              }
            } else {
              element = t(route.config.title, route.config.titleArgs);
            }
          } else {
            return '';
          }
          return element.replace('-', ' ');
        };

        const elements: string[] = pathname.split('/').filter((e: string) => e);
        const breadItems = elements.map((element, index) => {
          const partialRoute = generatePartialRoute(elements, index);
          if (index === elements.length - 1) {
            element = getRouteTitle(element, partialRoute);
            if (id) {
              IndexDb.pushHistory(id, partialRoute, state, element);
            }
            return (
              <Typography color="textPrimary" key={index} className={classes.capitalize}>
                {element}
              </Typography>
            );
          }
          element = getRouteTitle(element, partialRoute);
          if (!element) {
            return null;
          }
          return (
            <Link component={RouterLink} color="inherit"
                  to={{
                    pathname: partialRoute,
                    state,
                  }}
                  key={index} className={classes.capitalize}>
              {element}
            </Link>
          );
        });

        return ([
          ...breadItems,
        ]);
      },
      [id, pathname, classes, state, t],
    );
    return (
      <Box my={2}>
        <Grid container alignItems={'center'}>
          {back &&
          <Grid item>
            <Tooltip title={t('back')!}>
              <IconButton
                id={'back-btn'}
                onClick={() => moveBack()}>
                <ArrowBack fontSize={'small'}/>
              </IconButton>
            </Tooltip>
          </Grid>}
          <Grid item xs>
            <Breadcrumbs aria-label="breadcrumb">
              {items}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Box>
    );
  },
);
