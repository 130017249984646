import React, { memo, useMemo } from 'react';
import { Breadcrumb } from '../shared-ui';
import { SharedDialog } from '@hu-care/react-ui-store';
import { Layout as HuCareLayout } from '@hu-care/react-layout';
import { NavBar } from '../shared-ui/navigation';
import { routes, RouteType } from '../../routes';
import { matchPath, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  // https://dfmcphee.com/flex-items-and-min-width-0/
  main: { // TODO move in @hu-care/react-layout#Layout?
    minWidth: 0,
  },
}));

export const getMatchedRoute = (pathname: string) => {
  const getMatch = (routes: RouteType[]): RouteType | null => {
    for (const route of routes) {
      const match = matchPath(pathname, { path: route.path, exact: true });
      if (match) {
        return route;
      }
      if (route.children?.length) {
        return getMatch(route.children!);
      }
    }
    return null;
  };
  return getMatch(routes);
};

export const Layout = memo(({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const matchedRoute = useMemo(() => getMatchedRoute(pathname), [pathname]);

  const config = matchedRoute?.config;

  return (
    <HuCareLayout
      navBar={props => <NavBar {...props}/>}
      mainClassName={classes.main}>
      {config?.breadcrumbs && <Breadcrumb back={config.back}/>}
      <Container
        maxWidth={config?.maxWidth || 'lg'}
        style={{ padding: 0 }}>
        <div>
          {children}
        </div>
      </Container>
      <SharedDialog/>
    </HuCareLayout>
  );
});
