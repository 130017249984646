import React, { memo, useCallback, useEffect, useState } from 'react';
import { AppBar, Avatar, IconButton, Link, Toolbar, Typography } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useAuth0 } from '@hu-care/react-auth0';
import { matchPath, useHistory, useLocation } from 'react-router';
import { UserMenu } from './user-menu';
import clsx from 'clsx';
import { SideBar } from '../side-bar/side-bar';
import { useStyles } from '../styles';
import { commonRoutes } from '../../../../routes/common.routes';
import { useTrialUser } from '../../../../hooks';
import { RoleType } from '../../../../generated/graphql';
import { ConfigurationSideBar } from '../side-bar/configuration-side-bar';

interface NavbarProps {
  title?: string;
}

const menuId = 'primary-search-account-menu';

const hasSidebarFn = (path: string) => {
  const matched = commonRoutes.find(r => matchPath(path, { path: r.path, exact: true }));
  return !matched?.config.hideSidebar;
};

export const NavBar = memo<NavbarProps>(
  () => {
    const classes = useStyles();
    const { user } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const history = useHistory();
    const location = useLocation();
    const defaultOpen = localStorage.getItem('SIDEBAR_TINY');
    const [open, setOpen] = useState(defaultOpen === 'true');

    const { user: trialUser } = useTrialUser();

    useEffect(
      () => localStorage.setItem('SIDEBAR_TINY', String(open)),
      [open],
    );
    const hasSidebar = hasSidebarFn(location.pathname);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    useEffect(() => {
      if (!hasSidebar) {
        setOpen(false);
      }
    }, [hasSidebar]);

    const SidebarComponent = trialUser && (trialUser?.role === RoleType.Pd ? ConfigurationSideBar : SideBar);
    const handleMenuClose = useCallback(
      () => {
        setAnchorEl(null);
      }, [setAnchorEl],
    );

    return (
      <div>
        <AppBar
          position={'fixed'}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open && SidebarComponent,
          })}
        >
          <Toolbar>
            {!open && hasSidebar && (
              <IconButton
                id={'open-nav-bar-btn'}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(true)}
              >
                <MenuIcon/>
              </IconButton>
            )}
            <Link style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => history.push('/')}>
              <Typography className={classes.title} variant="h6" style={{ color: 'white' }} noWrap>
                {'HuCare Trial'}
              </Typography>
            </Link>
            <div className={classes.grow}/>
            {trialUser &&
            <Typography variant="subtitle2">
              Current Role: <strong>{trialUser.role}</strong>
            </Typography>
            }
            <div>
              <IconButton
                edge="end"
                id={'handle-profile-menu-btn'}
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit">
                <Avatar
                  src={user?.picture}
                  className={classes.avatar}
                />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <UserMenu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        />
        {hasSidebar && SidebarComponent && <SidebarComponent open={open} onClose={() => setOpen(false)}/>}
      </div>
    );
  });
