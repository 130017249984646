import React, { useCallback, useMemo, useState } from 'react';
import { Actions, useStore } from '@hu-care/react-ui-store';
import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface StepProps {
  label: string;
  createButtonLabel: string;
}

export interface StepChildrenProps {
  activeStep: number;
  setCompleted: (bool: boolean) => void;
  setLoading: (bool: boolean) => void;
  onComplete: (...args: any) => void;
}

export interface ConfigStepperProps {
  entityUid?: string;
  steps: [StepProps, StepProps];
  children: (props: StepChildrenProps) => JSX.Element | null | undefined;
}

export const ConfigStepper: React.FC<ConfigStepperProps> = ({ entityUid, steps, children }) => {
  const { t } = useTranslation();
  const { dispatch } = useStore();
  const [activeStep, setActiveStep] = useState(entityUid ? 1 : 0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const setStepCompletion = (id: number) => (bool: boolean) => {
    setCompletedSteps(steps => {
      if (bool) {
        return steps.concat([id]);
      }
      return steps.filter(s => s !== id);
    });
  };

  const handleClose = useCallback(() => {
    dispatch({ type: Actions.DIALOG_CLOSE });
  }, [dispatch]);

  const handleNext = useCallback(() => {
    if (activeStep === steps.length - 1) {
      handleClose();
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, [activeStep, setActiveStep, steps, handleClose]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const childrenProps = useMemo<StepChildrenProps>(() => {
    const setCompleted = setStepCompletion(activeStep);
    return {
      activeStep,
      setLoading,
      setCompleted,
      onComplete: () => {
        setCompleted(true);
        setActiveStep(activeStep => ++activeStep);
      },
    };
  }, [activeStep, setLoading]);

  const getStepAction = useMemo(() => {
    if (completedSteps.includes(activeStep)) {
      return (
        <Button
          id={'next-btn'}
          variant="contained"
          color="primary"
          onClick={handleNext}>
          {loading ? <CircularProgress size={24}/> : t('next')}
        </Button>
      );
    }
    return (
      <Button
        id={`${steps[activeStep].createButtonLabel}-btn`}
        variant={'outlined'}
        color={'primary'}
        type={'submit'}
        fullWidth
        form="step-form">
        {loading ? <CircularProgress size={24}/> : steps[activeStep].createButtonLabel}
      </Button>
    );
  }, [loading, t, activeStep, handleNext, completedSteps, steps]);

  return (
    <Box my={2}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <Stepper activeStep={activeStep}>
            {steps.map((step) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={step.label} {...stepProps}>
                  <StepLabel>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item>
          {children(childrenProps)}
        </Grid>
        <Grid item>
          <Grid container justify={'space-between'}>
            <Grid item>
              <Button
                id={'back-btn'}
                disabled={activeStep === 0 || (activeStep === 1 && completedSteps.includes(0))}
                onClick={handleBack}>
                {t('back')}
              </Button>
            </Grid>
            <Grid item>
              {getStepAction}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
