import {
  AccountBalance,
  Assignment,
  AssignmentInd,
  AssignmentTurnedIn,
  Dashboard,
  Message,
  People,
  Schedule,
  Tune,
} from '@material-ui/icons';
import { ConfigurationStatusHint, RoleType } from '../generated/graphql';
import React from 'react';

export interface Item {
  completeIcon?: boolean;
  complete?: boolean;
  disabled?: boolean;
  to: string;
  perm?: string;
  hint?: string;
  label: string;
  labelProp?: Record<string, string>;
  icon: any;
}

export const configurationMenuItems: Item[] = [
  {
    to: '/',
    label: 'dashboard',
    icon: <Dashboard/>,
  },
  {
    completeIcon: true,
    complete: true,
    to: '/settings',
    label: 'trial:settings',
    icon: <Tune/>,
    hint: ConfigurationStatusHint.Settings,
  },
  {
    completeIcon: true,
    complete: true,
    to: '/form-list',
    label: 'trial:form_plural',
    icon: <Assignment/>,
    hint: ConfigurationStatusHint.Form,
  },
  {
    completeIcon: true,
    complete: true,
    to: '/control-plan',
    label: 'trial:monitor_plan',
    icon: <AssignmentTurnedIn/>,
  },
  {
    completeIcon: true,
    complete: true,
    to: '/patient-diaries',
    label: 'trial:patient_diaries',
    icon: <AssignmentInd/>,
  },
  {
    completeIcon: true,
    complete: false,
    to: '/scheduling',
    label: 'trial:scheduling',
    icon: <Schedule/>,
    hint: ConfigurationStatusHint.Schedules,
  },
  {
    completeIcon: true,
    complete: true,
    to: '/cro-list',
    perm: 'cro:list',
    label: 'cro',
    icon: <AccountBalance/>,
    hint: ConfigurationStatusHint.Cro,
  },
];

export const croMenuItems: Item[] = [
  {
    to: '/',
    label: 'dashboard',
    icon: <Dashboard/>,
  },
  {
    to: '/patients',
    label: 'patients',
    icon: <People/>,
  },
];

export const siteMenuItems: Item[] = [
  {
    to: '/',
    label: 'dashboard',
    icon: <Dashboard/>,
  },
  {
    to: '/patients',
    label: 'patients',
    icon: <People/>,
  },
  {
    to: '/forms',
    label: 'forms',
    icon: <Assignment/>,
  },
  {
    to: '/queries',
    label: 'queries',
    icon: <Message/>,
  },
];

export const monitorMenuItems: Item[] = [
  {
    to: '/',
    label: 'dashboard',
    icon: <Dashboard/>,
  },
  {
    to: '/forms',
    label: 'forms',
    icon: <Assignment/>,
  },
  {
    to: '/queries',
    label: 'queries',
    icon: <Message/>,
  },
];

export const roleMenuItems: Record<any, Item[]> = {
  [RoleType.Pd]: configurationMenuItems,
  [RoleType.Cd]: croMenuItems,
  [RoleType.Pi]: siteMenuItems,
  [RoleType.Cr]: monitorMenuItems,
  [RoleType.Sc]: siteMenuItems,
};
