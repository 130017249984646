import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Auth0Provider } from '@hu-care/react-auth0';
import { StoreProvider } from '@hu-care/react-ui-store';
import theme from '@hu-care/theme';
import history from './routes/history';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';
import { ErrorBoundary } from './components/error.boundary';
import MomentUtils from '@date-io/moment';

// A function that routes the user to the right place after login
const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

console.log(`HuCare Trial v. ${process.env.REACT_APP_VERSION}`);
ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider
      permissionClaim="https://api.hu-care.com/permissions"
      domain={process.env.REACT_APP_AUTH_DOMAIN!}
      client_id={process.env.REACT_APP_AUTH_CLIENT_ID!}
      audience={process.env.REACT_APP_AUTH_AUDIENCE!}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline/>
          <StoreProvider>
            <App/>
          </StoreProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Auth0Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
