import React from 'react';
import { Route } from 'react-router-dom';
import { RoutesKeys, RouteType } from './utils';
import { ProtectedAuth0Route, ProtectedTrialRoute } from '../components/shared-ui';
import { commonRoutes } from './common.routes';
import { getRouteFactory } from '@hu-care/react-utils';
import { configurationRoutes } from './configuration.routes';
import { RoleType } from '../generated/graphql';
import { croRoutes } from './cro.routes';
import { siteRoutes } from './site.routes';
import { errorRoutes } from './error.routes';
import { monitorRoutes } from './monitor.routes';

export const setBreadCrumb = (key: string, uuid: string, title: string) => {
  return {
    [key]: {
      title,
      uuid,
    },
  };
};

export * from './utils';

export const routes: RouteType[] = [
  ...commonRoutes,
  ...configurationRoutes,
  ...croRoutes,
  ...siteRoutes,
  ...monitorRoutes,
  ...errorRoutes,
];

export const routesByRole: Record<any, RouteType[]> = {
  [RoleType.Pd]: configurationRoutes,
  [RoleType.Cd]: croRoutes,
  [RoleType.Pi]: siteRoutes,
  [RoleType.Cr]: monitorRoutes,
  [RoleType.Sc]: siteRoutes,
};

export const routeByKey = routes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);

export const renderRoutes = (routes: RouteType[]) => routes.map(({ component, exact, path, config: { scopes, auth0Scopes } }) => {
  if (auth0Scopes) {
    return (
      <ProtectedAuth0Route perms={auth0Scopes} key={path} exact={exact} path={path} component={component}/>
    );
  }
  if (scopes) {
    return (
      <ProtectedTrialRoute perms={scopes} key={path} exact={exact} path={path} component={component}/>
    );
  }
  return (
    <Route key={path} exact={exact} path={path} component={component}/>
  );
});
