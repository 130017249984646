import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

function requireAll(r: any) {
  return Object.fromEntries(
    r.keys().map(function (mpath: any, ...args: any) {
      const result = r(mpath, ...args);
      const name = mpath
        .replace(/(?:^[./]*\/|\.[^.]+$)/g, '') // Trim
        .replace(/\//g, '_') // Relace '/'s by '_'s
      ;
      return [name, result];
    }),
  );
}

const itNs = requireAll((require as any).context('./assets/i18n/it/', true, /\.json$/));
const enNs = requireAll((require as any).context('./assets/i18n/en/', true, /\.json$/));

i18next
  .use(initReactI18next)
  .init({
    resources: {
      it: itNs,
      en: enNs,
    },
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'common',
    returnEmptyString: false,
    appendNamespaceToMissingKey: false,
    lng: process.env.REACT_APP_DEFAULT_LANGUAGE,
  });

export default i18next;
