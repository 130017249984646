import React from 'react';
import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';
import { Redirect } from 'react-router-dom';

export const commonRoutes: RouteType[] = [
  {
    key: RoutesKeys.dashboard,
    component: asyncLoad(() => import('../containers/trial-list/trial-list.container')),
    path: '/',
    exact: true,
    config: {
      title: 'dashboard',
      breadcrumbs: false,
      back: false,
      hideSidebar: true,
    },
  },
  {
    key: RoutesKeys.acceptInvite,
    path: '/accept-invite',
    exact: true,
    component: asyncLoad(() => import('../containers/accept-invite/accept-invite.container')),
    config: {
      title: 'common:accept-invite',
      breadcrumbs: false,
      back: false,
      hideSidebar: true,
    },
  },
  {
    key: RoutesKeys.trial,
    path: '/trials/trial-:trial_uuid',
    component: asyncLoad(() => import('../containers/trial/trial.container')),
    config: {
      title: 'common:trial',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.profile,
    path: '/profile',
    component: asyncLoad(() => import('../containers/profile/profile.container')),
    config: {
      title: 'common:profile',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.trials,
    path: '/trials',
    exact: true,
    component: () => <Redirect to="/"/>,
    config: {
      title: 'common:trial_list',
    },
  },
];
