import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

export const croRoutes: RouteType[] = [
  {
    key: RoutesKeys.croDashboard,
    path: '/trials/trial-:trial_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-cro/trial-cro-dashboard.container')),
    config: {
      title: 'cro:cro_home',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.croSite,
    path: '/trials/trial-:trial_uuid/site-:site_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-site-detail/trial-site-detail.container')),
    config: {
      title: 'site:detail',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.patients,
    path: '/trials/trial-:trial_uuid/patients',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-cro/trial-cro-patients.container')),
    config: {
      title: 'common:patients',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.croPatients,
    path: '/trials/trial-:trial_uuid/site-:site_uuid/patients',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/patients/patients.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patients',
    },
  },
];

