import React, { memo, useCallback } from 'react';
import { Avatar, ListItemIcon, Menu, MenuProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Email, ExitToApp, Person } from '@material-ui/icons';
import { Actions, useStore } from '@hu-care/react-ui-store';
import { useAuth0 } from '@hu-care/react-auth0';
import { capitalize } from '@hu-care/react-utils';
import { useTranslation } from 'react-i18next';
import { AcceptInvite } from '../../../invite';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  avatar: {
    marginBottom: theme.spacing(1),
  },
  noHover: {
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
}));

export type UserItemProps = MenuProps;

export const UserMenu = memo<UserItemProps>(menuProps => {
  const classes = useStyles();
  const { logout, user } = useAuth0();
  const { t } = useTranslation();
  const { dispatch } = useStore();

  const onClose = menuProps.onClose;

  const onInvite = useCallback(() => {
    dispatch({
      type: Actions.DIALOG_OPEN,
      title: t('invite:accept_invite'),
      maxWidth: 'sm',
      fullWidth: true,
      body: <AcceptInvite/>,
      onClose: () => dispatch({ type: Actions.DIALOG_CLOSE }),
    });
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  }, [dispatch, t, onClose]);

  if (!user) {
    return null;
  }

  return (
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...menuProps}
    >
      <MenuItem disableGutters disableRipple className={classes.noHover}>
        <div className={classes.root}>
          <Avatar src={user.picture} className={classes.avatar}/>
          <Typography variant="subtitle2">{capitalize(user.nickname)}</Typography>
          <Typography variant="caption">{user.email}</Typography>
        </div>
      </MenuItem>
      <MenuItem component={Link} to={'/profile'}>
        <ListItemIcon className={classes.listItemIcon}>
          <Person/>
        </ListItemIcon>
        <Typography variant="body2">{t('profile')}</Typography>
      </MenuItem>
      <MenuItem onClick={() => logout()} divider>
        <ListItemIcon className={classes.listItemIcon}>
          <ExitToApp/>
        </ListItemIcon>
        <Typography variant="body2">{t('logout')}</Typography>
      </MenuItem>
      <MenuItem onClick={onInvite}>
        <ListItemIcon className={classes.listItemIcon}>
          <Email/>
        </ListItemIcon>
        <Typography variant="body2">{t('invite:received_invite')}</Typography>
      </MenuItem>
    </Menu>
  );
});
