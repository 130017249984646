import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

export const siteRoutes: RouteType[] = [
  {
    key: RoutesKeys.siteDashboard,
    path: '/trials/trial-:trial_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/trial-site-dashboard.container')),
    config: {
      title: 'common:home',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.sitePatients,
    path: '/trials/trial-:trial_uuid/patients',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/trial-site-patients.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patients',
    },
  },
  {
    key: RoutesKeys.patientDetails,
    path: '/trials/trial-:trial_uuid/patients/patient-:patient_uuid/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/patient-details.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patient',
    },
  },
  {
    key: RoutesKeys.siteHome,
    path: '/trials/trial-:trial_uuid/site-:site_uuid/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/trial-site-dashboard.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patients',
    },
  },
  {
    key: RoutesKeys.enrollPatient,
    path: '/trials/trial-:trial_uuid/site-:site_uuid/enroll-patient/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/trial-site-patients.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patients',
      titleArgs: { name: 'trial:patient' },
    },
  },
  {
    key: RoutesKeys.enrollPatient,
    path: '/trials/trial-:trial_uuid/site-:site_uuid/enroll-patient/patient-:patient_uuid?',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/enroll-patient/enroll-patient.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:enrollment',
      titleArgs: { name: 'trial:patient' },
    },
  },
  {
    key: RoutesKeys.compileForm,
    path: '/trials/trial-:trial_uuid/patients/patient-:patient_uuid/event-:event_uuid/compile-form-:form_uuid/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/form-compile/form-compile.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:compile_form',
      titleArgs: { name: 'trial:form' },
    },
  },
  {
    key: RoutesKeys.dropOutPatient,
    path: '/trials/trial-:trial_uuid/patients/patient-:patient_uuid/drop-out/event-:event_uuid/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-site/patient-drop-out-form.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'drop_out',
      titleArgs: { name: 'drop_out' },
    },
  },
  {
    key: RoutesKeys.readForm,
    path: '/trials/trial-:trial_uuid/patients/patient-:patient_uuid/event-:event_uuid/',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/form-compile/form-compile.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:form',
      titleArgs: { name: 'trial:form' },
    },
  },
  {
    key: RoutesKeys.eventForm,
    path: '/trials/trial-:trial_uuid/forms/form-:event_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-form.container')),
    config: {
      title: 'common:form',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.monitorForms,
    path: '/trials/trial-:trial_uuid/forms',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-forms.container')),
    config: {
      title: 'common:forms',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.monitorQueries,
    path: '/trials/trial-:trial_uuid/queries',
    exact: true,
    component: asyncLoad(() => import('../containers/trial-monitor/trial-monitor-queries.container')),
    config: {
      title: 'common:queries',
      breadcrumbs: true,
      back: true,
    },
  },
];

