import { useMutation } from '@apollo/client';
import { AcceptInviteDocument, AcceptInviteMutation, AcceptInviteMutationVariables } from '../generated/graphql';
import { useCallback } from 'react';
import { ErrorHandler } from '@hu-care/react-utils';
import { useStore } from '@hu-care/react-ui-store';
import { Cb } from '../interfaces';
import { errorToast } from '../utils/error-toast';

export interface AcceptInviteForm {
  code: string;
}

export const useAcceptInvite = (cb?: Cb<AcceptInviteMutation['acceptInvite']>) => {
  const [mutate, rest] = useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, {
    context: {
      headers: {
        'X-Hucare-Hash': '',
      },
    },
  });
  const { dispatch } = useStore();

  const acceptInvite = useCallback(async (variables: AcceptInviteForm) => {
    try {
      const result = await mutate({
        variables,
      });
      if (typeof cb === 'function') {
        cb(result.data!.acceptInvite);
      }
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
    }
  }, [mutate, dispatch, cb]);

  return {
    acceptInvite,
    ...rest,
  };
};
