export class IndexDb {
  static readonly currentVersion = 3;

  static init (id: string) {
    const request = indexedDB.open(id);
    request.onsuccess = () => {
      const db = request.result;
      this.upgradeIfNeeded(db);
    };
    request.onerror = () => {
      console.error('Why didn\'t you allow my web app to use IndexedDB?!');
    };
  };

  static delete (id: string) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(id);
      request.onsuccess = resolve;
      request.onerror = reject;
    })
  }

  static needsUpgrade (db: IDBDatabase) {
    return db.version !== this.currentVersion;
  }

  static async upgrade (db: IDBDatabase) {
    db.close();
    await this.delete(db.name);

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(db.name, this.currentVersion);
      request.onerror = reject;

      request.onsuccess = () => resolve(db);

      request.onupgradeneeded = () => {
        db = request.result;
        const historyStore = db.createObjectStore('history', { keyPath: 'pathname', autoIncrement: false });
        historyStore.createIndex('timestamp', 'timestamp');

        resolve(db);
      };
    })
  }

  static upgradeIfNeeded (db: IDBDatabase) {
    const mustUpgrade = this.needsUpgrade(db);

    return mustUpgrade
      ? this.upgrade(db)
      : Promise.resolve(db);
  }

  static pushHistory (trialId: string, pathname: string, state: any, label: string) {
    let request: any = indexedDB.open(trialId);
    request.onsuccess = function (event: any) {
      const db: IDBDatabase = event.target.result;
      if (db.objectStoreNames.contains('history')) {
        const transaction = db.transaction('history', 'readwrite');
        const history = transaction.objectStore('history');
        request = history.put({
          pathname,
          state,
          label,
          timestamp: Date.now(),
        });
        request.onerror = function () {
          console.warn('Error', request.error);
        };
      }
    };
  };

  static async getHistory (id: string, slice?: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        let request = indexedDB.open(id);
        let db;

        request.onsuccess = async (event: any) => {
          db = event.target.result;

          if (db.objectStoreNames.contains('history')) {
            try {
              const transaction = db.transaction('history', 'readonly');
              const history = transaction.objectStore('history');
              const index = history.index('timestamp');

              const routes: any[] = [];

              request = index.openCursor();
              request.onsuccess = function (event: any) {
                const cursor = event.target.result;
                if (cursor) {
                  routes.push(cursor.value);
                  cursor.continue();
                } else {
                  const sliceStart = (!slice || slice > routes.length)
                    ? 0
                    : routes.length - slice
                  resolve(routes.slice(sliceStart, routes.length).reverse());
                }
              }
              request.onerror = reject;
            } catch (e) {
              reject(e);
            }
          }
        };
        request.onerror = reject;
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };
}
