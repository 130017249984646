import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';

export const configurationRoutes: RouteType[] = [
  {
    key: RoutesKeys.trial,
    path: '/trials/trial-:trial_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-dashboard/trial-dashboard.container')),
    config: {
      title: 'common:trial',
      breadcrumbs: true,
      back: true,
    },
  },
  {
    key: RoutesKeys.croList,
    path: '/trials/trial-:trial_uuid/cro-list',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-cro-list/trial-cro-list.container')),
    config: {
      title: 'trial:cro-list',
      breadcrumbs: true,
      back: true,
      scopes: 'cro:list',
    },
  },
  {
    key: RoutesKeys.cro,
    path: '/trials/trial-:trial_uuid/cro-list/cro-:cro_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-cro-detail/trial-cro-detail.container')),
    config: {
      breadcrumbs: true,
      back: true,
      scopes: 'cro:show',
      title: 'cro:detail',
    },
  },
  {
    key: RoutesKeys.site,
    path: '/trials/trial-:trial_uuid/cro-list/cro-:cro_uuid/site-:site_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-site-detail/trial-site-detail.container')),
    config: {
      breadcrumbs: true,
      back: true,
      scopes: 'site:show',
      title: 'site:detail',
    },
  },
  {
    key: RoutesKeys.settings,
    path: '/trials/trial-:trial_uuid/settings',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-settings/trial-settings.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:settings',
    },
  },
  {
    key: RoutesKeys.patientSchedules,
    path: '/trials/trial-:trial_uuid/patient-schedules',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/events-calendar/events-calendar.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patient_calendar',
    },
  },
  {
    key: RoutesKeys.scheduling,
    path: '/trials/trial-:trial_uuid/scheduling',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/trial-scheduling/trial-scheduling.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:scheduling',
    },
  },
  {
    key: RoutesKeys.controlPlan,
    path: '/trials/trial-:trial_uuid/control-plan',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/control-plan/control-plan.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:monitor_plan',
    },
  },
  {
    key: RoutesKeys.patientDiaries,
    path: '/trials/trial-:trial_uuid/patient-diaries',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/patient-diaries/patient-diaries.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:patient_diaries',
    },
  },
  {
    key: RoutesKeys.formList,
    path: '/trials/trial-:trial_uuid/form-list',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/form-list/form-list.container')),
    config: {
      breadcrumbs: true,
      back: true,
      title: 'trial:form_plural',
    },
  },
  {
    key: RoutesKeys.editForm,
    path: '/trials/trial-:trial_uuid/form-list/form-:form_uuid',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/form-builder/form-builder.container')),
    config: {
      breadcrumbs: false,
      back: false,
      title: 'edit',
      titleArgs: { name: 'trial:form' },
      maxWidth: 'xl',
    },
  },
  {
    key: RoutesKeys.summary,
    path: '/trials/trial-:trial_uuid/summary',
    exact: true,
    component: asyncLoad(() => import('../containers/trial/summary/summary.container')),
    config: {
      breadcrumbs: false,
      back: true,
      title: 'summary',
        maxWidth: 'xl',
    },
  },
];
