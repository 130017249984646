import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useStore } from '@hu-care/react-ui-store';
import { useAuth0 } from '@hu-care/react-auth0';
import { CreateCroDocument, CreateCroMutation, CreateCroMutationVariables } from '../generated/graphql';
import { Cb } from '../interfaces';
import { ErrorHandler } from '@hu-care/react-utils';
import { errorToast } from '../utils/error-toast';

export interface CreateCroForm {
  code: string;
  description: string;
  operator: {
    email: string;
    description: string;
  };
}

export const useCreateCro = (cb?: Cb<CreateCroMutation['createCro']>) => {
  const { dispatch } = useStore();
  const { getTokenSilently } = useAuth0();
  const [mutate, rest] = useMutation<CreateCroMutation, CreateCroMutationVariables>(CreateCroDocument, {
    refetchQueries: ['GetCros', 'ConfigurationStatus'],
  });

  const createCro = useCallback(async (values: CreateCroForm) => {
    try {
      const result = await mutate({
        variables: {
          ...values,
          authToken: `${await getTokenSilently()}`,
        },
      });
      if (typeof cb === 'function') {
        cb(result.data!.createCro);
      }
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
    }
  }, [mutate, cb, getTokenSilently, dispatch]);

  return {
    createCro,
    ...rest,
  };
};
