import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

export const useResetStore = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    client.resetStore().then(() => setLoading(false)).catch(err => {
      console.error(err);
      setLoading(false);
    });
  }, [client]);

  return loading;
};
