import React from 'react';
import { Box, LinearProgress, LinearProgressProps, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number; title: string }) => {
  return (
    <Box mb={2}>
      <Grid container justify={'space-between'}>
        <Grid item>
          <Typography variant={'overline'}>{props.title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'overline'}>
            {props.value}%
          </Typography>
        </Grid>
      </Grid>
      <LinearProgress variant={'determinate'} {...props}/>
    </Box>
  )
}
