import React, { forwardRef } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0 } from '@hu-care/react-auth0';
import { useTrialUser } from '../../../hooks';
import Page403Container from '../../../containers/page-403.container';

type ProtectedRouteProps = RouteProps & {
  perms: string | string[];
};

const InternalProtectedRoute = forwardRef<Route, ProtectedRouteProps & { userCan: (scope: string) => boolean }>(
  ({ perms, userCan, component, ...props }, ref,
  ) => {
    const safePerms = !Array.isArray(perms) ? [perms] : perms;
    const Comp = component as any;

    return (
      <Route
        ref={ref}
        {...props}
        render={rProps => {
          return !safePerms.some(userCan) ? <Page403Container/> : <Comp {...rProps}/>
        }}
      />
    );
  });

export const ProtectedTrialRoute = forwardRef<Route, ProtectedRouteProps>((props, ref) => {
  const { userCan } = useTrialUser();
  return <InternalProtectedRoute {...props} userCan={userCan} ref={ref}/>;
});

export const ProtectedAuth0Route = forwardRef<Route, ProtectedRouteProps>((props, ref) => {
  const { userCan } = useAuth0();
  return <InternalProtectedRoute {...props} userCan={userCan} ref={ref}/>;
});
