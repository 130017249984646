import {
  CreateOperatorDocument,
  CreateOperatorMutation,
  CreateOperatorMutationVariables,
  GetCroDocument,
  GetCroQuery,
  GetCroQueryVariables,
  GetSiteDocument,
  GetSiteQuery,
  GetSiteQueryVariables,
  InviteOperatorDocument,
  InviteOperatorMutation,
  InviteOperatorMutationVariables,
  RoleType,
  StoreInviteDocument,
  StoreInviteMutation,
  StoreInviteMutationVariables,
} from '../generated/graphql';
import { useMutation } from '@apollo/client';
import { useTrialToken } from './useTrialToken';
import { Cb } from '../interfaces';
import { ErrorHandler } from '@hu-care/react-utils';
import { useStore } from '@hu-care/react-ui-store';
import { errorToast } from '../utils/error-toast';

export interface UseAddOperatorsProps {
  trialUid: string;
  croUid: string;
  siteUid?: string;
}

export interface OperatorFormElem {
  role: RoleType;
  email: string;
  description: string;
}

export interface AddOperatorForm {
  operators: OperatorFormElem[];
}

export const useAddOperators = ({ trialUid, croUid, siteUid }: UseAddOperatorsProps, cb?: Cb<CreateOperatorMutation['createOperator']>) => {
  const { dispatch } = useStore();
  const { data: trialToken } = useTrialToken(trialUid);

  const [storeInviteMutation, {
    loading: l1,
    error: e1,
  }] = useMutation<StoreInviteMutation, StoreInviteMutationVariables>(StoreInviteDocument);

  const [createMutation, {
    loading: l2,
    error: e2,
  }] = useMutation<CreateOperatorMutation, CreateOperatorMutationVariables>(CreateOperatorDocument, {
    update: (cache, mutationResult) => {
      try {
        const newOp = mutationResult.data?.createOperator;

        if (siteUid) {
          const oldSite = cache.readQuery<GetSiteQuery, GetSiteQueryVariables>({
            query: GetSiteDocument,
            variables: { id: siteUid },
          });

          if (oldSite?.getSite && newOp) {
            cache.writeQuery<GetSiteQuery, GetSiteQueryVariables>({
              query: GetSiteDocument,
              variables: { id: siteUid },
              data: {
                getSite: {
                  ...oldSite.getSite,
                  operators: [...oldSite.getSite.operators, newOp],
                  primaryInvestigators: newOp.role === RoleType.Pi ? [
                    ...oldSite.getSite.primaryInvestigators,
                  ] : [...oldSite.getSite.primaryInvestigators, newOp],
                },
              },
            });
          }
        } else {
          const oldCro = cache.readQuery<GetCroQuery, GetCroQueryVariables>({
            query: GetCroDocument,
            variables: { id: croUid },
          });

          if (oldCro?.getCro && newOp) {
            cache.writeQuery<GetCroQuery, GetCroQueryVariables>({
              query: GetCroDocument,
              variables: { id: croUid },
              data: {
                getCro: {
                  ...oldCro.getCro,
                  operators: [...oldCro.getCro.operators, newOp],
                  directors: newOp.role === RoleType.Cd ? [
                    ...oldCro.getCro.directors,
                  ] : [...oldCro.getCro.directors, newOp],
                },
              },
            });
          }
        }
      } catch (e) {
        // Nothing to update
      }
    },
  });

  const [inviteMutation, {
    loading: l3,
    error: e3,
  }] = useMutation<InviteOperatorMutation, InviteOperatorMutationVariables>(InviteOperatorDocument, {
    context: {
      headers: {
        'X-Hucare-Hash': '',
      },
    },
  });

  const createOperators = async (values: AddOperatorForm) => {
    try {
      await Promise.all(values.operators.map(
        async (op) => {
          const { role, description, email } = op;
          const { data: opResponse } = await createMutation({
            variables: {
              description: description,
              role: role,
              cro_id: croUid,
              site_id: siteUid,
            },
          });
          const operator_id = opResponse!.createOperator!.id;
          const { data } = await inviteMutation({
            variables: {
              email: email,
              role: role,
              operator_id,
              token: trialToken!.exchangeToken,
            },
          });
          await storeInviteMutation({
            variables: {
              operator_id,
              invite_code: data!.inviteOperator,
              email: email,
            },
          });
          if (typeof cb === 'function') {
            cb(opResponse!.createOperator);
          }
        }));
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
    }
  };

  return {
    createOperators,
    loading: l1 || l2 || l3,
    error: e1 || e2 || e3,
  };
};
