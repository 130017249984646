import { useMutation } from '@apollo/client';
import { CreateTrialDocument, CreateTrialMutation, CreateTrialMutationVariables } from '../generated/graphql';
import { useCallback } from 'react';
import { ErrorHandler } from '@hu-care/react-utils';
import { useStore } from '@hu-care/react-ui-store';
import { Cb } from '../interfaces';
import { errorToast } from '../utils/error-toast';

export interface CreateTrialForm {
  description: string;
}

export const useCreateTrial = (cb?: Cb<CreateTrialMutation['createTrial']>) => {
  const [mutate, rest] = useMutation<CreateTrialMutation, CreateTrialMutationVariables>(CreateTrialDocument, {
    context: {
      headers: {
        'X-Hucare-Hash': '',
      },
    },
    refetchQueries: ['GetAvailableTrials'],
    awaitRefetchQueries: true,
  });
  const { dispatch } = useStore();

  const createTrial = useCallback(async (variables: CreateTrialForm) => {
    try {
      const result = await mutate({
        variables,
      });
      if (typeof cb === 'function') {
        cb(result.data!.createTrial);
      }
    } catch (e) {
      const { message } = new ErrorHandler(e);
      dispatch(errorToast(message));
      throw e;
    }
  }, [mutate, dispatch, cb]);

  return {
    createTrial,
    ...rest,
  };
};
