import { MutationUpdaterFn } from '@apollo/client';
import {
  GetConfigurationValuesDocument,
  GetConfigurationValuesQuery,
  GetConfigurationValuesQueryVariables,
} from '../generated/graphql';

import { ConfigType } from '../components/trial-configuration/trial.class';

export const updateConfig = <TResult>(resultKey: string, newConfig: ConfigType[]): MutationUpdaterFn<TResult> => (cache, mutationResult) => {
  try {
    const getConfig = cache.readQuery<GetConfigurationValuesQuery, GetConfigurationValuesQueryVariables>({
      query: GetConfigurationValuesDocument,
    });
    if (getConfig?.getConfigurationValues && (mutationResult.data as any)[resultKey]) {
      cache.writeQuery<GetConfigurationValuesQuery, GetConfigurationValuesQueryVariables>({
        query: GetConfigurationValuesDocument,
        data: {
          getConfigurationValues: newConfig,
        },
      });
    }
  } catch (e) {}
};