import React, { memo } from 'react';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import clsx from 'clsx';
import { useStyles } from '../styles';
import { ItemList } from './items';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TrialHistory from './trial-history';
import { useQuery } from '@apollo/client';
import { GetTrialCredQuery } from '../../../../generated/graphql';
import { GET_TRIAL_CRED } from '../../../../apollo/localSchema';

export interface SideBarProps {
  open: boolean;
  onClose: () => void;
}

export const SideBar = memo<SideBarProps>(({ open, onClose }) => {
  const classes = useStyles();
  const { data } = useQuery<GetTrialCredQuery>(GET_TRIAL_CRED);
  const trialId = data?.trialCred?.id;
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Grid container direction={'column'} spacing={3} style={{ height: '100%' }} wrap={'nowrap'}>
        <Grid item>
          <Grid container justify={'space-between'} alignItems={'center'} className={classes.toolbar}>
            <Grid item>
              <IconButton
                id={'close-nav-bar-btn'}
                onClick={onClose} color={'inherit'}>
                <ChevronLeft/>
              </IconButton>
            </Grid>
          </Grid>
          <Divider/>
        </Grid>
        <Grid item>
          <ItemList open={open}/>
        </Grid>
        <Divider/>
        <Grid item xs hidden={!open}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-end'}>
            {trialId && <TrialHistory id={trialId}/>}
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
});
